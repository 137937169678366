/**
 * This is a reusable component for whatsapp functionality
 * mobile views of multiple headers
 */

import { whatsappMessageRender } from '@/utils/whatsappMessageRender';
import { WhatsAppIcon } from '@/assets/svgExports/WhatsAppIcon';
import { HeaderSectionListItem, MenuItem } from './index.styles';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';

const MobileWhatsAppComp = (props) => {
  const { storePhone } = useStoreContactDetails();

  const onContactBtnClick = () => {
    whatsappMessageRender();
  };

  return (
    !!storePhone && (
      <HeaderSectionListItem onClick={onContactBtnClick}>
        <WhatsAppIcon size={24} />
        <MenuItem secondaryColor={props.secondaryColor || '#000000'}>
          Contact on WhatsApp
        </MenuItem>
      </HeaderSectionListItem>
    )
  );
};

export default MobileWhatsAppComp;
